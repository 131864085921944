
* {
    margin: 0;
}

html {
    font-size: 16px;
}

body {
    background-color: #e3f2fd78;
}